<template>
    <div class="event-signup container fade-in">
        <div class="content">
            <EventManagement v-if="authedUser" :token="token" :userPosting="authedUser" />

            <form v-else @submit.prevent="verifyToken" class="input-group">
                <input v-model="token" type="password" placeholder="*********" />
                <button type="submit" :disabled="loginDisabled">Verify</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

const LOCALSTORAGE_FAILED_ATTEMPT_KEY = '_cma_fat'

const EventManagement = () => import('@/components/eventAdmin/EventManagement.vue')

export default {
    name: 'EventAdmin',

    props: {},

    data() {
        return {
            token: '',
            failedLoginAttempts: 0,
            authedUser: null,
        }
    },

    created() {
        if (localStorage.getItem(LOCALSTORAGE_FAILED_ATTEMPT_KEY)) {
            this.$router.push('/')
        }
    },

    components: {
        EventManagement,
    },

    computed: {
        loginDisabled() {
            return this.token.length < 5
        },
    },

    methods: {
        verifyToken() {
            if (this.token.length > 5) {
                if (this.failedLoginAttempts < 3) {
                    const url =
                        process.env.NODE_ENV === 'production'
                            ? '/signups/events/login/'
                            : 'http://localhost/signups/events/login/'

                    axios
                        .post(url, { token: this.token })
                        .then(response => {
                            this.failedLoginAttempts = 0

                            this.authedUser = response.data.user
                        })
                        .catch(error => {
                            console.log(error)

                            this.authedUser = null

                            // eslint-disable-next-line no-plusplus
                            this.failedLoginAttempts++

                            alert('Nope')
                        })
                } else {
                    localStorage.setItem(LOCALSTORAGE_FAILED_ATTEMPT_KEY, 'true')
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import '../scss/eventAdmin';
</style>
